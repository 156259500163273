import { Box, Button, FormControl, FormLabel, Grid, InputAdornment, TextField } from "@material-ui/core";
import { GetApp, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import { columnsGroup } from "./helpers/groupOption";
import { getScheduleGroupByType, getScheduleGroupByTypeForExcel } from "../../../apis/scheduleApi";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { convertDateTimeFromApi } from "../../../utils/format";
import exportToExcel from "../../../utils/exportToExcel";

export default function ContentGroup() {
    // state or variable
    const tableRef = useRef(null);
    const controllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [optionCourse, setOptionCourse] = useState([]);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isExport, setIsExport] = useState(false);
    const [valuesUser, setValuesSearchUser] = useState({
        student: "",
        teacher: "",
    });
    const [valuesFilter, setValuesFilter] = useState({
        start_d: new Date(),
        end_d: new Date(),
        course: "",
    });

    // Export Excel
    const handleExportExcel = useCallback(async () => {
        setIsExport(true);
        try {
            const body = {
                search: valuesSearch,
                student: valuesUser?.student,
                teacher: valuesUser?.teacher,
                start: valuesFilter?.start_d,
                end: valuesFilter?.end_d,
                scheduleGroupType: "1",
                courseId: valuesFilter?.course?.id,
            };
            const res = await getScheduleGroupByTypeForExcel(body, controllerRef?.current?.signal);
            if (res) {
                const data = res?.map(row => {
                    return {
                        "Booking Date": row?.bookingStartDate ? convertDateTimeFromApi(row?.bookingStartDate) : "",
                        "Start Time": row?.bookingStartDate ? moment(row?.bookingStartDate).format("HH:mm") : "",
                        "End Time": row?.bookingEndDate ? moment(row?.bookingEndDate).format("HH:mm") : "",
                        Student: row?.ScheduleGroupListHasStudents?.Student
                            ? `${row?.ScheduleGroupListHasStudents?.Student?.studentFirstname || ""} ${row
                                  ?.ScheduleGroupListHasStudents?.Student?.studentLastname || ""}`
                            : "",
                        Course: row?.ScheduleGroup?.Course?.courseName || "",
                        Menu: row?.ScheduleGroupListHasMenus?.CourseMenu?.courseMenuName || "",
                        Teacher: row?.ScheduleGroupListHasMenus?.Teacher
                            ? `ครู ${row?.ScheduleGroupListHasMenus?.Teacher?.teacherFirstname || ""} ${row
                                  ?.ScheduleGroupListHasMenus?.Teacher.teacherLastname || ""}`
                            : "",
                        Classroom: row?.Classroom?.classroomName || "",
                        "Room Name": row?.ScheduleGroup?.roomName || "",
                        Note: row?.ScheduleGroup?.note || "",
                    };
                });
                if (data.length < 1) return false;
                exportToExcel(data, { fileName: "schedule-group-list" });
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsExport(false);
        }
    }, [valuesFilter, valuesUser, valuesSearch]);

    // onChangeFilter
    const handlerInputFilter = useCallback(
        (value, type = "") => {
            setValuesFilter(prev => ({ ...prev, [type]: value }));
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ page: 0 });
            }
        },
        [tableRef],
    );

    // search input
    const handleOnchangeSearchUser = useCallback(
        (evt, type) => {
            const value = evt.target?.value;
            if (type === 1) {
                // student
                setValuesSearchUser(state => ({ ...state, student: value }));
                if (tableRef?.current !== null) {
                    tableRef.current.onQueryChange({ user: value, page: 0 });
                }
            } else {
                // teacher
                setValuesSearchUser(state => ({ ...state, teacher: value }));
                if (tableRef?.current !== null) {
                    tableRef.current.onQueryChange({ teacher: value, page: 0 });
                }
            }
        },
        [tableRef],
    );
    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ search: value, page: 0 });
            }
        },
        [tableRef],
    );

    // clear filter
    const handleClearInputFilter = useCallback(() => {
        setValuesFilter({
            start_d: new Date(),
            end_d: new Date(),
            course: null,
        });
        setValuesSearch("");
        setValuesSearchUser({
            student: "",
            teacher: "",
        });
        if (tableRef?.current !== null) {
            tableRef.current.onQueryChange({ search: "", page: 0 });
        }
    }, []);

    // fetch data table
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    user: valuesUser?.student || "",
                    teacher: valuesUser?.teacher || "",
                    search: query?.search || "",
                    order: query?.orderBy?.field || "scheduleGroupId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    scheduleGroupType: "1",
                };
                if (valuesFilter?.course !== null) {
                    queryParams.courseId = valuesFilter?.course?.id;
                }
                if (valuesFilter?.start_d !== null) {
                    queryParams.startDate = moment(valuesFilter?.start_d).format("YYYY-MM-DD");
                }
                if (valuesFilter?.end_d !== null) {
                    queryParams.endDate = moment(valuesFilter?.end_d).format("YYYY-MM-DD");
                }
                const response = await getScheduleGroupByType(queryParams, controllerRef.current?.signal);
                if (response) {
                    // set option
                    setOptionCourse(
                        response?.option?.optionCourse.length > 0
                            ? response?.option?.optionCourse.map(row => row?.Course)
                            : [],
                    );
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: response?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "scheduleGroupId",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: response?.data || [],
                        page: query?.page || 0,
                        totalCount: response?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [valuesFilter, valuesUser],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.

        return () => {
            controllerRef.current.abort();
        };
    }, [controllerRef]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-lg-5">
                <div className="row mb-3 mb-lg-5">
                    <div className="col-12 col-md-8 col-xl-9 mb-3">
                        <div className="row">
                            <div className="col-12 col-md-4 mb-3 d-flex align-items-center">
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={valuesSearch}
                                        onChange={handleOnchangeSearch}
                                        placeholder="Search..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-md-4 mb-3 d-flex align-items-center">
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={valuesUser?.student}
                                        onChange={event => handleOnchangeSearchUser(event, 1)}
                                        placeholder="Search Student..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-md-4 mb-3 d-flex align-items-center">
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={valuesUser?.teacher}
                                        onChange={event => handleOnchangeSearchUser(event, 2)}
                                        placeholder="Search Teacher..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-md-5 mb-3 d-flex align-items-center">
                                <FormLabel className="mr-1 mb-0">Course</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        value={valuesFilter.course}
                                        options={optionCourse}
                                        getOptionLabel={option => option?.courseName}
                                        getOptionValue={option => option?.id}
                                        onChange={value => handlerInputFilter(value, "course")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-7 mb-3 d-flex align-items-center">
                                <FormLabel className="mr-1 mb-0">Booking Date</FormLabel>
                                <div className="flex-fill">
                                    <DatePickerApp
                                        fullWidth
                                        size="small"
                                        value={valuesFilter.start_d}
                                        onChange={date => {
                                            if (date > valuesFilter?.end_d) {
                                                handlerInputFilter(date, "end_d");
                                            }
                                            handlerInputFilter(date, "start_d");
                                        }}
                                    />
                                </div>
                                <small className="mx-2">To</small>
                                <div className="flex-fill">
                                    <DatePickerApp
                                        fullWidth
                                        size="small"
                                        value={valuesFilter.end_d}
                                        minDate={valuesFilter.start_d}
                                        onChange={date => handlerInputFilter(date, "end_d")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 mb-3">
                        <div className="w-100 d-flex flex-column flex-xl-row align-items-center justify-content-between">
                            <Button
                                onClick={handleClearInputFilter}
                                size="large"
                                className="btn btn-lg t-btn-primary mb-3 mb-xl-0 mr-xl-2 fixed-h-lg flex-grow-0">
                                Clear
                            </Button>
                            <Button
                                size="large"
                                className="btn t-btn-primary flex-grow-0 fixed-h-lg"
                                disabled={isExport}
                                onClick={handleExportExcel}>
                                {isExport ? "Loading..." : <GetApp style={{ fontSize: 22 }} />}
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom w-100">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        data={fetchData}
                        columns={columnsGroup()}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
