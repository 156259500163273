import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, FormLabel, Grid, InputAdornment, TextField } from "@material-ui/core";
import { GetApp, Search } from "@material-ui/icons";
import moment from "moment";
import MaterialTable from "material-table";
import { capitalize } from "lodash";
import {
    getMenuOption,
    getTransactionReport,
    exportTransactionReport,
    getTotalTransactionReport,
} from "../../../apis/reportApi";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import exportToExcel from "../../../utils/exportToExcel";
import { getColumnsTableTransactionReport } from "./helpers/transactionReportOption";
import { getOrdinalSuffix } from "../../../utils/number";
import { currencyTHB } from "../../../utils/formatCurrency";

export default function TransactionReportContent() {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // component state
    const [queryParamsState, setQueryParamsState] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, sorting: false });
    const [totalData, setTotalData] = useState({
        grandTotal: 0,
        packageTotal: 0,
    });
    const [dataFilter, setDataFilter] = useState({
        order: {},
        student: {},
        startDate: null,
        endDate: null,
        bookingStartDate: null,
        bookingEndDate: null,
        product: "",
        user: "",
    });
    const [filterError, setFilterError] = useState({
        startDate: "",
        endDate: "",
        bookingStartDate: "",
        bookingEndDate: "",
    });
    const [options, setOption] = useState({
        studentOption: [],
        orderOption: [],
    });

    // fetch option
    const fetchOption = useCallback(async () => {
        try {
            const option = await getMenuOption({ type: "5" }, controllerRef?.current?.signal);
            if (option) {
                setOption(option);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change date filter
    const handleChangeDateFilter = useCallback((value, typeField) => {
        if (value) {
            const timestamp = moment(value).unix();
            if (Number.isNaN(timestamp) || timestamp < 0) {
                setFilterError(prevState => ({
                    ...prevState,
                    [typeField]: "Invalid Date",
                }));
                return;
            }
        }
        setDataFilter(prevState => ({ ...prevState, [typeField]: moment(value).format("YYYY-MM-DD") }));
        setFilterError(prevState => ({
            ...prevState,
            [typeField]: "",
        }));
    }, []);

    // clear filter
    const handleClearFilter = useCallback(() => {
        setDataFilter({
            student: {},
            order: {},
            startDate: null,
            endDate: null,
            bookingStartDate: null,
            bookingEndDate: null,
            product: "",
            user: "",
        });
        setFilterError({
            startDate: "",
            endDate: "",
            bookingStartDate: "",
            bookingEndDate: "",
        });
    }, []);

    // fetch export data
    const fetchExportData = useCallback(async queryParams => {
        const transactionReportExcel = await exportTransactionReport(queryParams, controllerRef?.current?.signal);
        if (transactionReportExcel) {
            const excel = transactionReportExcel?.map((report, index) => ({
                No: index + 1,
                "Booking ID": report?.bookingNo || "",
                Student: report?.studentName || "",
                "Date of booking":
                    report?.Order?.OrderLists?.filter(item => item?.bookingDate)
                        ?.map(item => (item?.bookingDate ? moment(item?.bookingDate).format("DD/MM/YYYY") : ""))
                        ?.join(", ") || "",
                Product: report?.Order?.OrderLists?.map(item => item?.name)?.join(", ") || "",
                "Sale Total": report?.saleTotal || "",
                "Date & Time of Transaction": report?.transactionDate || "",
                "Transaction ID": report?.orderHasPaymentId || "",
                "Transaction Amount": report?.payment || "",
                Package: report?.package || "",
                SCB: report?.scb || "",
                "K Bank": report?.kbank || "",
                BAY: report?.bay || "",
                "Credit Card": report?.credit || "",
                Kshop: report?.kshop || "",
                ผ่อนผ่านบัตร: report?.installment || "",
                TTB: report?.ttb || "",
                PAYPAL: report?.paypal || "",
                UOB: report?.uob || "",
                Cash: report?.cash || "",
                Transfer: report?.transfer || "",
                Other: report?.other || "",
                Status: Number(report?.status)
                    ? `${report?.status}${getOrdinalSuffix(Number(report?.status))} Payment`
                    : capitalize(report?.status),
                User: report?.user || "",
            }));
            return excel || [];
        }
        return [];
    }, []);

    // export excel
    const handleExportExcel = useCallback(async () => {
        const resp = await fetchExportData(queryParamsState);
        if (resp.length < 1) return false;
        exportToExcel(resp, {
            fileName: "report-transaction",
            sumColumn: [
                "Sale Total",
                "Transaction Amount",
                "Package",
                "SCB",
                "K Bank",
                "BAY",
                "Credit Card",
                "Kshop",
                "ผ่อนผ่านบัตร",
                "TTB",
                "PAYPAL",
                "UOB",
                "Cash",
                "Transfer",
                "Other",
            ],
        });
    }, [fetchExportData, queryParamsState]);

    const fetchTotal = useCallback(async () => {
        const queryParams = {
            // order: query?.orderBy?.field || "orderHasPaymentId",
            // direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            studentId: dataFilter?.student?.id,
            orderId: dataFilter?.order?.orderId,
            startDate: dataFilter?.startDate ? moment(dataFilter?.startDate).format("YYYY-MM-DD") : undefined,
            endDate: dataFilter?.endDate ? moment(dataFilter?.endDate).format("YYYY-MM-DD") : undefined,
            bookingStartDate: dataFilter?.bookingStartDate
                ? moment(dataFilter?.bookingStartDate).format("YYYY-MM-DD")
                : undefined,
            bookingEndDate: dataFilter?.bookingEndDate
                ? moment(dataFilter?.bookingEndDate).format("YYYY-MM-DD")
                : undefined,
            product: dataFilter?.product,
            user: dataFilter?.user,
            status: "0,1,3", // only `Paid`, `Partial`, `Deposit` status.
        };

        const response = await getTotalTransactionReport(queryParams, controllerRef?.current?.signal);
        setTotalData({
            grandTotal: response?.grandTotal || 0,
            packageTotal: response?.packageTotal || 0,
        });
    }, [dataFilter]);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    // order: query?.orderBy?.field || "orderHasPaymentId",
                    // direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    studentId: dataFilter?.student?.id,
                    orderId: dataFilter?.order?.orderId,
                    startDate: dataFilter?.startDate ? moment(dataFilter?.startDate).format("YYYY-MM-DD") : undefined,
                    endDate: dataFilter?.endDate ? moment(dataFilter?.endDate).format("YYYY-MM-DD") : undefined,
                    bookingStartDate: dataFilter?.bookingStartDate
                        ? moment(dataFilter?.bookingStartDate).format("YYYY-MM-DD")
                        : undefined,
                    bookingEndDate: dataFilter?.bookingEndDate
                        ? moment(dataFilter?.bookingEndDate).format("YYYY-MM-DD")
                        : undefined,
                    product: dataFilter?.product,
                    user: dataFilter?.user,
                    status: "0,1,3", // only `Paid`, `Partial`, `Deposit` status.
                };
                setQueryParamsState(queryParams);
                const transactionReport = await getTransactionReport(queryParams, controllerRef?.current?.signal);
                if (transactionReport) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: transactionReport?.pagination?.totalData || 0,
                    }));
                    setIsLoading(false);
                    return {
                        data: transactionReport?.data || [],
                        page: query?.page || 0,
                        totalCount: transactionReport?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [dataFilter],
    );

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        return () => {
            controllerRef.current.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataFilter.startDate && dataFilter.endDate && dataFilter.startDate > dataFilter.endDate) {
            setFilterError(prevState => ({
                ...prevState,
                endDate: `Date should be after ${moment(dataFilter.startDate).format("DD/MM/YYYY")}`,
            }));
        }
        if (
            dataFilter.bookingStartDate &&
            dataFilter.bookingEndDate &&
            dataFilter.bookingStartDate > dataFilter.bookingEndDate
        ) {
            setFilterError(prevState => ({
                ...prevState,
                bookingEndDate: `Date should be after ${moment(dataFilter.bookingStartDate).format("DD/MM/YYYY")}`,
            }));
        }
    }, [dataFilter]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (tableRef.current) {
                tableRef.current.onQueryChange({ page: 0 });
            }
            fetchTotal();
        }, 1000);
        return () => {
            clearTimeout(delayDebounceFn);
        };
        // eslint-disable-next-line
    }, [dataFilter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizeColumns = React.useMemo(() => getColumnsTableTransactionReport(), []);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Student :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ id: "" }, ...options?.studentOption]}
                                        value={
                                            options?.studentOption?.find(it => it?.id === dataFilter?.student?.id) || ""
                                        }
                                        onChange={value => {
                                            setDataFilter(prevState => ({
                                                ...prevState,
                                                student: value?.id ? value : {},
                                            }));
                                        }}
                                        getOptionLabel={option => {
                                            if (!option?.id) return "All";
                                            return option?.student_firstname && option?.student_lastname
                                                ? `${option?.student_firstname} ${option?.student_lastname} (${option?.student_nickname})`
                                                : "";
                                        }}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Booking ID :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ orderId: "" }, ...options?.orderOption]}
                                        value={
                                            options?.orderOption?.find(
                                                it => it?.orderId === dataFilter?.order?.orderId,
                                            ) || ""
                                        }
                                        onChange={value => {
                                            setDataFilter(prevState => ({
                                                ...prevState,
                                                order: value?.orderId ? value : {},
                                            }));
                                        }}
                                        getOptionLabel={option => {
                                            if (!option?.orderId) return "All";
                                            return option?.bookingNo ? option?.bookingNo : "";
                                        }}
                                        getOptionValue={option => option?.orderId}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Booking Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.bookingStartDate}
                                        onChange={date => {
                                            handleChangeDateFilter(date, "bookingStartDate");
                                        }}
                                        error={Boolean(filterError.bookingStartDate)}
                                        helperText={filterError.bookingStartDate}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.bookingEndDate}
                                        minDate={dataFilter?.bookingStartDate || undefined}
                                        onChange={date => {
                                            handleChangeDateFilter(date, "bookingEndDate");
                                        }}
                                        error={Boolean(filterError.bookingEndDate)}
                                        helperText={filterError.bookingEndDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div className="d-flex align-items-center mt-lg-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill mr-2"
                                        onClick={handleClearFilter}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormLabel className="mb-md-0 mr-1">Product : </FormLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={dataFilter?.product}
                                    onChange={e => {
                                        setDataFilter(prevState => ({
                                            ...prevState,
                                            product: e.target.value,
                                        }));
                                    }}
                                    placeholder="Search Product..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search className="text-muted" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormLabel className="mb-md-0 mr-1">User : </FormLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={dataFilter?.user}
                                    onChange={e => {
                                        setDataFilter(prevState => ({
                                            ...prevState,
                                            user: e.target.value,
                                        }));
                                    }}
                                    placeholder="Search User..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search className="text-muted" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Transaction Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.startDate}
                                        onChange={date => {
                                            handleChangeDateFilter(date, "startDate");
                                        }}
                                        error={Boolean(filterError.startDate)}
                                        helperText={filterError.startDate}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.endDate}
                                        minDate={dataFilter?.startDate || undefined}
                                        onChange={date => {
                                            handleChangeDateFilter(date, "endDate");
                                        }}
                                        error={Boolean(filterError.endDate)}
                                        helperText={filterError.endDate}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <div className="d-flex align-items-center justify-content-lg-end mt-lg-6">
                            <Button
                                size="large"
                                className={`${isLoading &&
                                    "disabled"} btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill`}
                                onClick={handleExportExcel}>
                                <GetApp style={{ fontSize: 22 }} />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        data={fetchData}
                        components={{ Container: Box }}
                        columns={memoizeColumns}
                    />
                </div>
                <div className="d-flex justify-content-end px-20 mt-10">
                    {/* <div className="d-flex mr-6">
                        <p className="mr-5 h5">Sales Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(saleTotal)}
                        </p>
                    </div> */}
                    <div className="d-flex mr-6">
                        <p className="mr-5 h5">Package Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(totalData?.packageTotal || 0)}
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="mr-5 h5">Grand Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(totalData?.grandTotal || 0)}
                        </p>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
