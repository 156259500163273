import { Box, Button, Grid, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import { Close, GetApp } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import moment from "moment";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import { getColumnsTableReport } from "./helpers/options_report_monthly";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { getReportMonthly, getBranchOption } from "../../../apis/reportApi";
import { currencyNoSymbol, currencyTHB } from "../../../utils/formatCurrency";
import { swalCondition } from "../../../utils/swal";
import exportToExcel from "../../../utils/exportToExcel";

export default function MonthlySumContent() {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // component state
    const [data, setData] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
    const [filterBranch, setFilterBranch] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [saleTotal, setSaleTotal] = useState(0);
    const [packageTotal, setPackageTotal] = useState(0);
    // const [grandTotal, setGrandTotal] = useState(0);
    const [header, setHeader] = useState({
        branchId: 0,
        branchOption: [],
        isAdmin: false,
    });
    const [isShowModal, setIsShowModal] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        title: "",
        orders: [],
        totalTransaction: 0,
        grandTotal: 0,
    });

    // change filter
    const handleOnChangeDateFilter = useCallback(
        (date, indexField) => {
            if (date) {
                setFilterDate(prev => prev.map((r, i) => (i === indexField ? date : r)));
                if (indexField === 0) {
                    if (date.getTime() > filterDate[1].getTime()) {
                        setFilterDate(prev => prev.map((r, i) => (i === 1 ? date : r)));
                    }
                }
            }
        },
        [filterDate],
    );

    // change branch
    const handleOnChangeBranchFilter = useCallback(newValue => {
        setFilterBranch(newValue?.id || "");
    }, []);

    // export excel
    const handleExportExcel = useCallback(() => {
        if (dataExcel.length < 1) return false;
        exportToExcel(dataExcel, {
            fileName: "report-monthly-sales",
            sumColumn: ["Sale"],
        });
    }, [dataExcel]);

    // view order transaction
    const handleClickViewOrder = useCallback((data, section) => {
        setIsShowModal(true);
        let title = "";
        let field = "";
        let transaction = "";
        if (section === "transaction") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Transaction)`;
            field = "total";
            transaction = "totalTransaction";
        }
        if (section === "sales") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Sales Total)`;
            field = "sales";
            transaction = "salesTransaction";
        }
        if (section === "cash") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Cash)`;
            field = "cash";
            transaction = "cashTransaction";
        }
        if (section === "transfer") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Transfer)`;
            field = "transfer";
            transaction = "transferTransaction";
        }
        if (section === "credit") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Credit Card)`;
            field = "credit";
            transaction = "creditTransaction";
        }
        if (section === "other") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Other)`;
            field = "other";
            transaction = "otherTransaction";
        }
        if (section === "package") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Package)`;
            field = "package";
            transaction = "packageTransaction";
        }
        if (section === "scb") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (SCB)`;
            field = "scb";
            transaction = "scbTransaction";
        }
        if (section === "kbank") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (K Bank)`;
            field = "kbank";
            transaction = "kbankTransaction";
        }
        if (section === "bay") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (BAY)`;
            field = "bay";
            transaction = "bayTransaction";
        }
        if (section === "kshop") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (Kshop)`;
            field = "kshop";
            transaction = "kshopTransaction";
        }
        if (section === "installment") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (ผ่อนผ่านบัตร)`;
            field = "installment";
            transaction = "installmentTransaction";
        }
        if (section === "ttb") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (TTB)`;
            field = "ttb";
            transaction = "ttbTransaction";
        }
        if (section === "paypal") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (PAYPAL)`;
            field = "paypal";
            transaction = "paypalTransaction";
        }
        if (section === "uob") {
            title = `${moment(data?.date, "YYYY-MM-DD").format("DD/MM/YYYY")} (UOB)`;
            field = "uob";
            transaction = "uobTransaction";
        }
        const orders =
            data?.orders?.map(order => ({
                bookingNo: order?.bookingNo || "",
                transaction: order[transaction] || 0,
                total: order[field] || 0,
            })) || [];
        const totalTransaction =
            orders?.reduce((result, order) => {
                return result + order?.transaction;
            }, 0) || 0;
        const grandTotal =
            orders?.reduce((result, order) => {
                return result + order?.total;
            }, 0) || 0;
        setModalDetail({
            title,
            orders,
            totalTransaction,
            grandTotal,
        });
    }, []);

    // fetch data
    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const body = {
                branchId: filterBranch,
                startDate: filterDate[0],
                endDate: filterDate[1],
            };
            const res = await getReportMonthly(body, controllerRef?.current?.signal);
            if (res) {
                if (res?.isBranchActive === false) {
                    return await swalCondition("Your Branch is not active", "Please Contact Admin", {
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                setData(res);
                let saleSum = 0;
                let packageSum = 0;
                for (let i = 0; i < res?.length; i++) {
                    saleSum += Number.isNaN(res[i].sales) ? 0 : res[i].sales;
                }
                for (let i = 0; i < res?.length; i++) {
                    packageSum += Number.isNaN(res[i].package) ? 0 : res[i].package;
                }
                setSaleTotal(saleSum);
                setPackageTotal(packageSum);
                // setGrandTotal(saleSum + packageSum);
                const data = res?.map((report, index) => ({
                    Date: moment(report?.date).format("DD/MM/YYYY"),
                    Branch: report?.name,
                    Transaction: report?.totalTransaction || 0,
                    Sale: report?.sales || 0,
                    Package: report?.package || 0,
                    SCB: report?.scb || 0,
                    "K Bank": report?.kbank || 0,
                    BAY: report?.bay || 0,
                    "Credit Card": report?.credit || 0,
                    Kshop: report?.kshop || 0,
                    ผ่อนผ่านบัตร: report?.installment || 0,
                    TTB: report?.ttb || 0,
                    PAYPAL: report?.paypal || 0,
                    UOB: report?.uob || 0,
                    Cash: report?.cash || 0,
                    Transfer: report?.transfer || 0,
                    Other: report?.other || 0,
                    Summary: index === 0 ? saleSum : "",
                }));
                setDataExcel(data);
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsLoading(false);
        }
    }, [filterBranch, filterDate]);

    // fetch option
    const fetchOption = useCallback(async () => {
        try {
            const option = await getBranchOption(controllerRef?.current?.signal);
            if (option) {
                setHeader(option);
                if (!option?.isAdmin) {
                    setFilterBranch(option?.branchId);
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    useEffect(() => {
        const controllerRef = new AbortController();
        fetchOption();
        return () => controllerRef.current?.abort();
    }, [fetchOption]);

    useEffect(() => {
        fetch();
    }, [filterBranch, filterDate, fetch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizeColumns = React.useMemo(() => getColumnsTableReport({ handleClickViewOrder }), []);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <SelectSearchApp
                                    size="small"
                                    options={[{ branch_name: "All" }, ...header?.branchOption]}
                                    value={header?.branchOption?.find(it => it.id === filterBranch) || 0}
                                    onChange={handleOnChangeBranchFilter}
                                    getOptionLabel={option => option.branch_name}
                                    getOptionValue={option => option.id}
                                    isDisabled={!header?.isAdmin}
                                    placeholder="All"
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill mb-3 fixed-h-lg"
                                        size="small"
                                        value={filterDate[0]}
                                        onChange={date => {
                                            handleOnChangeDateFilter(date, 0);
                                        }}
                                    />
                                    <small className="mx-3 mb-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill mb-3 fixed-h-lg"
                                        size="small"
                                        minDate={filterDate[0]}
                                        value={filterDate[1]}
                                        onChange={date => {
                                            handleOnChangeDateFilter(date, 1);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <div className="d-flex">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary flex-fill flex-lg-grow-0 mr-2 fixed-h-lg"
                                        onClick={() => {
                                            setFilterDate([new Date(), new Date()]);
                                            if (header?.isAdmin) {
                                                setFilterBranch(0);
                                            }
                                        }}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <div className="d-flex align-items-center justify-content-lg-end">
                                    <Button
                                        size="large"
                                        className={`${dataExcel.length < 1 &&
                                            "disabled"} btn btn-lg t-btn-primary flex-fill flex-lg-grow-0 fixed-h-lg`}
                                        onClick={handleExportExcel}
                                        disabled={isLoading}>
                                        <GetApp style={{ fontSize: 22 }} />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={getOptionsTableApp}
                        components={{ Container: Box }}
                        data={data}
                        columns={memoizeColumns}
                    />
                </div>
                <div className="d-flex justify-content-end px-20 mt-10">
                    {/* <div className="d-flex mr-6">
                        <p className="mr-5 h5">Sales Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(saleTotal)}
                        </p>
                    </div> */}
                    <div className="d-flex mr-6">
                        <p className="mr-5 h5">Package Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(packageTotal)}
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="mr-5 h5">Grand Total :</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(saleTotal)}
                        </p>
                    </div>
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={isShowModal} scrollable centered onHide={() => setIsShowModal(false)}>
                    <Modal.Header>
                        <Modal.Title>{modalDetail?.title}</Modal.Title>
                        <IconButton onClick={() => setIsShowModal(false)}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table mb-10 FS16PX_P">
                            <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                                <tr className="text-center">
                                    <th>No.</th>
                                    <th>Receipt No.</th>
                                    <th>Transaction</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalDetail?.orders?.map((order, index) => (
                                    <tr key={index}>
                                        <td className="border text-center">{index + 1}</td>
                                        <td className="border text-left">{order?.bookingNo}</td>
                                        <td className="border text-right">{order?.transaction}</td>
                                        <td className="border text-right">{currencyNoSymbol(order?.total || "")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="border-0" />
                                    <td className="border-0" />
                                    <td className="border text-right" style={{ fontWeight: 800 }}>
                                        {modalDetail?.totalTransaction}
                                    </td>
                                    <td className="border text-right" style={{ fontWeight: 800 }}>
                                        {currencyNoSymbol(modalDetail?.grandTotal || "")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
