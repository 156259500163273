import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, FormLabel, Grid, IconButton } from "@material-ui/core";
import MaterialTable from "material-table";
import { Close, GetApp } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getOptionsTableApp } from "../../helpers/useOption";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { getColumnsTableCourseReport } from "./helpers/optionCourseReport";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";
import { getMenuOption, getReportCourse } from "../../../apis/reportApi";
import { currencyTHB } from "../../../utils/formatCurrency";
import { swalCondition } from "../../../utils/swal";
import exportToExcel from "../../../utils/exportToExcel";
import { useFilter } from "../../../hooks/useFilter";

export default function CourseSummaryContent() {
    // hook
    const history = useHistory();
    const { filter, setFilter, clearFilter } = useFilter();

    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // component state
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataFilter, setDataFilter] = useState({
        branch: {},
        course: {},
        date: [new Date(), new Date()],
        tags: [],
    });
    const [options, setOption] = useState({
        isAdmin: false,
        branchId: 0,
        branchOption: [],
        courseOption: [],
        levelOption: [],
        tagOption: [],
    });
    const [isShowModal, setIsShowModal] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        title: "",
        orders: [],
    });

    // function filter
    const handleOnchageFilter = useCallback((value, typeField, indexDate = null) => {
        setDataFilter(prevState => {
            const newDataFilter = {};
            if (typeField === "date" && indexDate !== null) {
                if (value) {
                    newDataFilter.date = prevState.date.map((d, idx) => (idx === indexDate ? value : d));
                    if (indexDate === 0) {
                        if (value.getTime() > prevState.date[1].getTime()) {
                            newDataFilter.date = prevState.date.map((d, idx) => (idx === 1 ? value : d));
                        }
                    }
                }
                return { ...prevState, ...newDataFilter };
            }
            newDataFilter[typeField] = value;
            return { ...prevState, ...newDataFilter };
        });
    }, []);

    // Export Excel
    const handleExportExcel = useCallback(() => {
        if (dataExcel.length < 1) return false;
        exportToExcel(dataExcel, { fileName: "report-course", sumColumn: ["Sale"] });
    }, [dataExcel]);
    // end

    // view transaction
    const handleViewTransaction = useCallback(data => {
        setIsShowModal(true);
        setModalDetail({
            title: data?.courseName || "",
            orders: data?.orders?.map(order => ({
                bookingNo: order?.bookingNo || "",
                date: order?.date ? moment(order?.date).format("DD/MM/YYYY") : "",
                studentName: order?.studentName || "",
                studentId: order?.studentId || 0,
            })),
        });
    }, []);

    const fetchOption = useCallback(async () => {
        try {
            const option = await getMenuOption({ type: "2" }, controllerRef?.current?.signal);
            if (option) {
                setOption(option);
                if (!option?.isAdmin) {
                    setDataFilter(state => ({ ...state, branch: { id: option?.branchId } }));
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const body = {
                branchId: dataFilter?.branch?.id,
                courseId: dataFilter?.course?.id,
                levelId: dataFilter?.skill?.id,
                tagId: dataFilter?.tags?.map(tag => tag?.tag_id),
                startDate: dataFilter?.date[0],
                endDate: dataFilter?.date[1],
            };
            const res = await getReportCourse(body, controllerRef?.current?.signal);
            if (res) {
                if (res?.isBranchActive === false) {
                    return await swalCondition("Your Branch is not active", "Please Contact Admin", {
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                let counter = 0;
                for (let i = 0; i < res?.length; i++) {
                    counter += Number.isNaN(res[i].sale) ? 0 : res[i].sale;
                }
                const excel = res?.map(report => ({
                    Branch: report?.branch,
                    Course: report?.courseName,
                    Tags: report?.tag?.join(", "),
                    Transaction: report?.transaction,
                    Sale: report?.sale || 0,
                }));
                setDataExcel(excel);
                setDataTable(res);
                setTotal(counter);
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsLoading(false);
        }
    }, [dataFilter]);

    // component didmount
    useEffect(() => {
        (async () => {
            if (filter && Object.entries(filter).length) {
                setDataFilter(prev => ({
                    ...prev,
                    ...filter,
                }));
                clearFilter();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        return () => controllerRef.current?.abort();
    }, [fetchOption]);

    useEffect(() => {
        fetch();
    }, [dataFilter, fetch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizeColumns = React.useMemo(() => getColumnsTableCourseReport({ handleViewTransaction }), []);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} className="d-xl-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Branch :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ branch_name: "All" }, ...options?.branchOption]}
                                        value={
                                            options?.branchOption?.find(it => it.id === dataFilter?.branch?.id) || ""
                                        }
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "branch")}
                                        getOptionLabel={option => option?.branch_name}
                                        getOptionValue={option => option?.id}
                                        isDisabled={!options?.isAdmin}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2} className="d-xl-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Course :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ course_name: "All", id: "" }, ...options?.courseOption]}
                                        value={options?.courseOption.find(dt => dt.id === dataFilter?.course.id) || ""}
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "course")}
                                        getOptionLabel={option => option?.course_name}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2} className="d-xl-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Tags :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchMultiApp
                                        size="sm"
                                        options={options?.tagOption}
                                        value={dataFilter.tags || {}}
                                        onChange={value => handleOnchageFilter(value, "tags")}
                                        getOptionLabel={option => option.tag_name}
                                        getOptionValue={option => option.tag_id}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-xl-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Order Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter.date[0]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 0);
                                        }}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        minDate={dataFilter.date[0]}
                                        value={dataFilter.date[1]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 1);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div className="d-flex align-items-center justify-content-between mt-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-grow-0 mr-5"
                                        onClick={() => {
                                            setDataFilter(state => ({
                                                branch: options?.isAdmin ? {} : state?.branch,
                                                course: {},
                                                skill: {},
                                                date: [new Date(), new Date()],
                                                tags: [],
                                            }));
                                        }}>
                                        Clear
                                    </Button>
                                    <Button
                                        size="large"
                                        className={`${dataExcel.length < 1 &&
                                            "disabled"} btn t-btn-primary fixed-h-lg flex-grow-0`}
                                        onClick={handleExportExcel}>
                                        <GetApp style={{ fontSize: 22 }} />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={getOptionsTableApp}
                        components={{ Container: Box }}
                        data={dataTable}
                        columns={memoizeColumns}
                    />
                </div>
                <div className="d-flex justify-content-end px-20 mt-10">
                    <div className="d-flex">
                        <p className="mr-5 h5">Grand Total</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(total)}
                        </p>
                    </div>
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={isShowModal} size="lg" scrollable centered onHide={() => setIsShowModal(false)}>
                    <Modal.Header>
                        <Modal.Title>{modalDetail?.title}</Modal.Title>
                        <IconButton onClick={() => setIsShowModal(false)}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table mb-10 FS16PX_P">
                            <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                                <tr className="text-center">
                                    <th>No.</th>
                                    <th>Receipt No.</th>
                                    <th>Date of booking</th>
                                    <th>Student</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalDetail?.orders?.map((order, index) => (
                                    <tr key={index}>
                                        <td className="border text-center">{index + 1}</td>
                                        <td className="border text-left">{order?.bookingNo}</td>
                                        <td className="border text-center">{order?.date}</td>
                                        <td
                                            className="border text-center"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setFilter(dataFilter);
                                                history.push(
                                                    order?.studentId
                                                        ? `/student_management/student_list/${order?.studentId}`
                                                        : "/student_management/student_list",
                                                );
                                            }}>
                                            {order?.studentName}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
